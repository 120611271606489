import Routing from './Routing/Router'
import './main.css'
import './App.css'

function App() {
  return (
    <div style={{ color:'white', fontSize:16,  }} className="text-center App bg-bgColor">
      <p>Out Of Date</p>
    </div>
  );
}

export default App;
